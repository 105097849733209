import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from 'react-i18next';
//import Backend from 'i18next-xhr-backend';

import en from '../locales/en/translation.json';
import de from '../locales/de/translation.json';




// Load the translation files into a Resource Bundle, organised by 
// Namespace. Add new languages by loading their translation files (like above)
// And add them to this resource bundle.
let resBundle = {
  en: {
    translation: en,
  },
  de: {
    translation: de,
  }
};

let debug = false;
if (process.env.DEBUG === 'true') {
  debug = true;
}

i18n
//.use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: "en",

    // have a common namespace used around the full app
    ns: ["translation"],
    defaultNS: "translation",
    resources: resBundle,
    debug: debug,

    interpolation: {
      escapeValue: false, // not needed for react!!
    },

    react: {
      bindI18n: 'languageChanged',
      useSuspense: true
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    }
  
  })

export default i18n